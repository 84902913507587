import React from 'react';

export default function BioPixelLogo() {
  return (
    <div className="biopixel-logo">
      <a href={"https://biopixel.tv/"}>
        <img src="/images/logo.png" alt="BioPixel" width={"35%"} />
      </a>
    </div>
  )
}