import React, { useMemo } from 'react';
import { Marker } from 'react-map-gl';
import { getDaysSince } from '../utilities/time';
import filter from '../utilities/color';

interface IAnimalMarkerProps {
  group: any;
  entry: any;
  state: MarkerState;
  onClick: any;
  onMouseEnter: any;
  onMouseLeave: any;
}

export enum MarkerState {
  NORMAL,
  HIGHLIGHTED,
  HIDDEN
}

export default function AnimalMarker(props: IAnimalMarkerProps) {
  const active = useMemo(() => {
    return getDaysSince(props.entry) <= 100;
  }, [props.entry])

  return (
    <Marker 
      latitude={props.entry.position.latitude}
      longitude={props.entry.position.longitude}
      onClick={props.onClick}>
      <div style={{ backgroundColor: 'rgba(0,0,0,0)', height: '30px', width: '30px'}}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}>
        <img src={`/pins/pin${!active ? '-inactive' : ''}.svg`} alt="Shark Marker" width="100%" style={{
          opacity: (props.state === MarkerState.HIDDEN ? 0.3 : 1.0),
          filter: filter(props.state === MarkerState.HIGHLIGHTED ? '#fff' : props.group.color)
        }} />
      </div>
    </Marker>
  )
}