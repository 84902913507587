import * as React from "react";

export function Play(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      fill="#fff"
      className="bi bi-play-fill"
      viewBox="0 0 16 16"
      {...props}
    >
      <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
    </svg>
  );
}

export function Pause(props) {
  return ( <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="#FFF"
    className="bi bi-pause"
    viewBox="0 0 16 16"
    {...props}
  >
    <path d="M6 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5zm4 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5z" />
  </svg>)
}

export function Replay (props) {
  return (<svg
    width="30x"
    height="30px"
    viewBox="-1.5 0 19 19"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>{"multimedia / 7 - multimedia, refresh, replay, video icon"}</title>
    <g
      id="Free-Icons"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g transform="translate(-1193.000000, -305.000000)" id="Group">
        <g transform="translate(1189.000000, 302.000000)" id="Shape">
          <path
            d="M12,7 C15.8659932,7 19,10.1340068 19,14 C19,17.8659932 15.8659932,21 12,21 C8.13400675,21 5,17.8659932 5,14"
            stroke="#FFFFFF"
            strokeWidth={1}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.8260915,10.8857148 L5.69568021,7.30477744 C5.47956961,7.17854145 5.43577155,6.93976099 5.5978545,6.77144635 C5.62566353,6.74256812 5.65860149,6.71691486 5.69568021,6.69525619 L10.8260915,3.11431886 C11.1502574,2.92496489 11.610137,2.97613213 11.8532614,3.2286041 C11.948511,3.32751578 12,3.4478202 12,3.5714598 L12,10.4285738 C12,10.7441638 11.6715145,11 11.2663072,11 C11.1075579,11 10.9530909,10.9598985 10.8260915,10.8857148 Z"
            fill="#FFFFFF"
          />
        </g>
      </g>
    </g>
  </svg>)
};