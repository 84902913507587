import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Layer, LayerProps, Source } from 'react-map-gl';
import { Slider, ToggleButton } from '@mui/material'

import { Play, Pause, Replay } from './VideoControls';
import { getDaysBetween, getDaysSince } from '../utilities/time';
import { getGenderText, getTravelDistance, getProtectedStatusText } from '../utilities/misc';

interface IPathProps {
  selected: any,
  species: any,
  path: Array<any>,
  color: string,
  onExitPath: any
}

function InfoBox(props: any) {
  const [gender, setGender] = useState('Unknown');

  const [distance, setDistance] = useState(0);
  const [duration, setDuration] = useState(0);

  const [lastPing, setLastPing] = useState(0);

  const [more, setMore] = useState(false);
  
  useEffect(() => {
    setGender(getGenderText(props.selected));
    setDistance(getTravelDistance(props.path));
    setDuration(getDaysSince(props.selected.tagged));
    setLastPing(getDaysSince(props.selected));
  }, [props.path, props.selected, props.history, setDistance])

  return (
    <div className='info-box'>
      <div className='info-box-header'>
        <p onClick={props.onExitPath}>
          &lsaquo; Back to All Tracking
        </p>
      </div>
      <div className='info-box-content'>
        <div style={{ borderRadius: '10px', width: '80px', height: '80px', backgroundImage: `url("${props.species ? props.species.thumbnail : ''}")`, backgroundSize: 'cover'}}></div>
        <div style={{ marginLeft: '40px' }}>
          <span style={{ fontSize: '1.5em', fontWeight: 'bold' }}>
            {props.selected.name}
          </span><br />
          <span style={{ fontSize: '0.9em' }}>
            {gender}, {props.selected.size}cm 
          </span><br />
          <span style={{ fontSize: '0.7em' }}>
            Last Ping: {lastPing > 365 
              ? (`${Math.floor(lastPing / 365)} year${lastPing > (365 * 2) ? 's' : ''} ago`)
              : `${lastPing} days ago`} 
          </span>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 'auto' }}>
          <div style={{ marginRight: "40px" }}>
            <span style={{ fontSize: "0.6rem" }}>TRACKED</span><br />
            <span style={{ fontSize: "1.5rem", fontWeight: 'bold' }}>{duration}</span><span style={{ fontSize: "0.8rem" }}>d</span>
          </div>
          <div style={{ marginRight: "40px" }}>
            <span style={{ fontSize: "0.6rem" }}>DISTANCE TRAVELLED</span><br />
            <span style={{ fontSize: "1.5rem", fontWeight: 'bold' }}>{distance.toFixed(0)}</span><span style={{ fontSize: "0.8rem" }}>kms</span>
          </div>
          <div>
            <span style={{ fontSize: "0.6rem" }}>AVG. SPEED</span><br />
            <span style={{ fontSize: "1.5rem", fontWeight: 'bold' }}>{ (distance / (duration - lastPing) /24).toFixed(1) }</span><span style={{ fontSize: "0.8rem" }}>km/h</span>
          </div>
        </div>
      </div>
      <div className='info-box-footer'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <span>{props.species ? props.species.name : props.selected.species}</span>
          <span style={{opacity: 0.5 }}> &nbsp;| &nbsp;{props.species
                ? props.species.scientificName
                : ''}</span>
        </div>
        <div>
          {props.species && props.species.description && (<span 
              onClick={() => setMore(!more)} 
              style={{ fontSize: "0.7rem", color: 'deepskyblue', cursor: 'pointer' }}>
                Show {more ? 'less' : 'more'}
          </span>)}
        </div>
        <div style={{ textDecoration: 'underline' }}>
          <span>{props.species && !props.species.protected && 'Not '}Protected</span>
          <span style={{ marginLeft: '10px' }}>IUCN: {getProtectedStatusText(props.species && props.species.status)}</span>
        </div>
        </div>
        {more && props.species.description}
      </div>
    </div>
  )
}

export default function Path(props: IPathProps) {
  const [geojson, setGeoJson] = useState<any>({
    'type': 'FeatureCollection',
    'features': [
      {
        'type': 'Feature',
        'geometry': {
          'type': 'LineString',
          'coordinates': []
        }
      }
    ]
  })
  const [position, setPosition] = useState<any>({
    'type': 'Point',
    'coordinates': []
  })
  const [date, setDate] = useState(
    new Date(props.path[0].date).toLocaleString().split(',')[0]
  )

  const [idx, setIdx] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isFinished, setIsFinished] = useState(false);

  const pathLayer: LayerProps = useMemo(() => { return {
    id: 'line-animation',
    type: 'line',
    paint: {
      'line-color': props.color,
      'line-width': 2,
      'line-opacity': 0.8
    }
  }}, [props.color]);
  
  const pointLayer: LayerProps = useMemo(() => { return {
    id: 'point-animation',
    type: 'circle',
    paint: {
      'circle-color': props.color,
      'circle-radius': 8
    }
  }}, [props.color]);
  

  const daysBetween = useMemo(() => {
    return getDaysBetween(props.path[0], props.path[props.path.length - 1])
  }, [props.path])

  const path = useMemo(() => props.path.slice().reverse(), [props.path]);

  const progressSlider = useCallback((value: number) => {
    if (value >= daysBetween) { 
      setIsPlaying(false);
      setIsFinished(true);
    } else {
      setIsFinished(false);
    }

    const coordinates = path
      .filter(p => getDaysBetween(path[0], p) <= (value as number))
      .map(p => [p.longitude >= 0 ? p.longitude : 360 + p.longitude, p.latitude]);

    setGeoJson({
      'type': 'FeatureCollection',
      'features': [
        {
          'type': 'Feature',
          'geometry': {
            'type': 'LineString',
            'coordinates': coordinates
          }
        }
      ]
    });
    setPosition({
      'type': 'Point',
      'coordinates': coordinates[coordinates.length-1]
    })
    var date = new Date(path[0].date);
    date.setDate(date.getDate() + value);
    
    setDate(date.toLocaleString().split(',')[0]);
    return value
  }, [path, daysBetween])

  useEffect(() => {
    const intervalId = isPlaying 
    ? setInterval(() => {
        setIdx(previousIdx => progressSlider(previousIdx + 1));
      }, 1000/30)
    : undefined;

    return () => clearInterval(intervalId);
  }, [isPlaying, geojson, progressSlider])

  function onChange(e: Event, value: number | number[]) {
    setIdx(progressSlider(value as number));
  }

  function onReplay(e: Event) {
    setIdx(0);
    setIsPlaying(true);
  }

  return (
    <>
    <InfoBox 
      selected={props.selected}
      path={path}
      species={props.species}
      onExitPath={props.onExitPath} />
    <Source type="geojson" data={geojson}>
      <Layer {...pathLayer} />
    </Source>
    <Source type="geojson" data={position}>
      <Layer {...pointLayer} />
    </Source>
    <div className="history-slider-container">
      <Slider
        className="history-slider"
        aria-label="Timeline"
        defaultValue={0}
        valueLabelDisplay="auto"
        valueLabelFormat={value => date}
        value={idx}
        step={1}
        marks
        min={0}
        max={daysBetween}
        onChange={onChange}
      />
      <ToggleButton
        className="history-slider-control"
        value="check"
        selected={true}
        onChange={() => setIsPlaying(!isPlaying)}
        style={{  fontSize: '40px', color: 'white', border: 0, backgroundColor: 'rgba(0,0,0,0)', height: '40px' }}
      >
        {isPlaying ? <Pause /> : (isFinished ? <Replay onClick={onReplay} /> : <Play /> )}
      </ToggleButton>
      <div style={{  fontSize: '1.5em', color: 'white', height: '42px' }}>{date}</div>
    </div>
    </>
  )
}