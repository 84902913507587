import React from 'react';
import BioPixelLogo from './BioPixelLogo';
import BioTrackerLogo from './BioTrackerLogo';
import Title from './Title';

export default function Header(props: any) {
  return (
    <div>
      <BioTrackerLogo />
      <Title opacity={props.opacity} />
      <BioPixelLogo />
    </div>
  )
}