import { Style } from 'mapbox-gl';

const mapStyle: Style = {
  version: 8,
  "glyphs": "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
  sources: {
    "mapbox://mapbox.satellite": {
      "url": "mapbox://mapbox.satellite",
      "type": "raster",
      "tileSize": 256
    },
    'raster-tiles': {
      type: 'raster',
      tiles: [
        'https://ecn.t0.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=587&mkt=en-gb&n=z',
        'https://ecn.t1.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=587&mkt=en-gb&n=z',
        'https://ecn.t2.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=587&mkt=en-gb&n=z',
        'https://ecn.t3.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=587&mkt=en-gb&n=z',
      ],
    },

    "composite": {
      "url": "mapbox://mapbox.mapbox-streets-v8",
      "type": "vector"
    }
  },
  layers: [

    {
      "id": "background",
      "type": "background",
      "metadata": {
        "mapbox:featureComponent": "satellite",
        "mapbox:group": "Satellite imagery, land"
      },
      "layout": {},
      "paint": {
        "background-color": "hsl(222, 56%, 4%)",
        "background-opacity": 0.9
      }
    },
    {
      "type": 'raster',
      "id": 'bing-tiles',
      "source": 'raster-tiles',
      minzoom: 0,
      maxzoom: 22,
      "layout": {},
      "paint": {}
    },
    {
      "id": "satellite",
      "type": "raster",
      "metadata": {
        "mapbox:featureComponent": "satellite",
        "mapbox:group": "Satellite imagery, land"
      },
      "source": "mapbox://mapbox.satellite",
      maxzoom: 5,
      
      "layout": {
        
      },
      "paint": {
        "raster-fade-duration": 2000,
        "raster-opacity": 1,
        "raster-opacity-transition": {
          "duration": 2000
        }
      }
    },
    {
      "id": "admin-1-boundary-bg",
      "type": "line",
      "metadata": {
        "mapbox:featureComponent": "admin-boundaries",
        "mapbox:group": "Administrative boundaries, admin"
      },
      "source": "composite",
      "source-layer": "admin",
      "minzoom": 7,
      "filter": [
        "all",
        ["==", ["get", "admin_level"], 1],
        ["==", ["get", "maritime"], "false"],
        ["match", ["get", "worldview"], ["all", "US"], true, false]
      ],
      "paint": {
        "line-color": "hsl(0, 0%, 89%)",
        "line-width": [
          "interpolate",
          ["linear"],
          ["zoom"],
          3,
          6,
          12,
          12
        ],
        "line-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          7,
          0,
          8,
          0.5
        ],
        "line-dasharray": [1, 0],
        "line-blur": ["interpolate", ["linear"], ["zoom"], 3, 0, 12, 6]
      }
    },
    {
      "id": "admin-1-boundary",
      "type": "line",
      "metadata": {
        "mapbox:featureComponent": "admin-boundaries",
        "mapbox:group": "Administrative boundaries, admin"
      },
      "source": "composite",
      "source-layer": "admin",
      "minzoom": 2,
      "filter": [
        "all",
        ["==", ["get", "admin_level"], 1],
        ["==", ["get", "maritime"], "false"],
        ["match", ["get", "worldview"], ["all", "US"], true, false]
      ],
      "layout": {},
      "paint": {
        "line-dasharray": [
          "step",
          ["zoom"],
          ["literal", [2, 0]],
          7,
          ["literal", [2, 2, 6, 2]]
        ],
        "line-width": [
          "interpolate",
          ["linear"],
          ["zoom"],
          3,
          0.6,
          12,
          3
        ],
        "line-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          2,
          0,
          3,
          1
        ],
        "line-color": "hsl(0, 0%, 72%)"
      }
    },
    {
      "id": "admin-0-boundary-disputed",
      "type": "line",
      "metadata": {
        "mapbox:featureComponent": "admin-boundaries",
        "mapbox:group": "Administrative boundaries, admin"
      },
      "source": "composite",
      "source-layer": "admin",
      "minzoom": 1,
      "filter": [
        "all",
        ["==", ["get", "disputed"], "true"],
        ["==", ["get", "admin_level"], 0],
        ["==", ["get", "maritime"], "false"],
        ["match", ["get", "worldview"], ["all", "US"], true, false]
      ],
      "paint": {
        "line-color": "hsl(0, 0%, 72%)",
        "line-width": [
          "interpolate",
          ["linear"],
          ["zoom"],
          3,
          0.5,
          12,
          2
        ],
        "line-dasharray": [
          "step",
          ["zoom"],
          ["literal", [3, 2, 5]],
          7,
          ["literal", [2, 1.5]]
        ]
      }
    },
    {
      "id": "natural-line-label",
      "type": "symbol",
      "metadata": {
        "mapbox:featureComponent": "natural-features",
        "mapbox:group": "Natural features, natural-labels"
      },
      "source": "composite",
      "source-layer": "natural_label",
      "minzoom": 4,
      "filter": [
        "all",
        [
          "match",
          ["get", "class"],
          [
            "glacier",
            "landform",
            "disputed_glacier",
            "disputed_landform"
          ],
          ["match", ["get", "worldview"], ["all", "US"], true, false],
          false
        ],
        ["<=", ["get", "filterrank"], 2],
        ["==", ["geometry-type"], "LineString"]
      ],
      "layout": {
        "text-size": [
          "step",
          ["zoom"],
          ["step", ["get", "sizerank"], 18, 5, 12],
          17,
          ["step", ["get", "sizerank"], 18, 13, 12]
        ],
        "text-max-angle": 30,
        "text-field": ["coalesce", ["get", "name_en"], ["get", "name"]],
        "text-font": ["DIN Pro Medium", "Arial Unicode MS Regular"],
        "symbol-placement": "line-center",
        "text-pitch-alignment": "viewport"
      },
      "paint": {
        "text-halo-width": 0.5,
        "text-halo-color": "hsl(0, 0%, 100%)",
        "text-halo-blur": 0.5,
        "text-color": "hsl(0, 0%, 63%)"
      }
    },
    {
      "id": "water-line-label",
      "type": "symbol",
      "metadata": {
        "mapbox:featureComponent": "natural-features",
        "mapbox:group": "Natural features, natural-labels"
      },
      "source": "composite",
      "source-layer": "natural_label",
      "minzoom": 1,
      "filter": [
        "all",
        [
          "match",
          ["get", "class"],
          [
            "bay",
            "ocean",
            "reservoir",
            "sea",
            "water",
            "disputed_bay",
            "disputed_ocean",
            "disputed_reservoir",
            "disputed_sea",
            "disputed_water"
          ],
          ["match", ["get", "worldview"], ["all", "US"], true, false],
          false
        ],
        ["==", ["geometry-type"], "LineString"]
      ],
      "layout": {
        "text-size": [
          "interpolate",
          ["linear"],
          ["zoom"],
          0,
          ["*", ["-", 16, ["sqrt", ["get", "sizerank"]]], 1],
          22,
          ["*", ["-", 22, ["sqrt", ["get", "sizerank"]]], 1]
        ],
        "text-max-angle": 30,
        "text-letter-spacing": [
          "match",
          ["get", "class"],
          "ocean",
          0.25,
          ["sea", "bay"],
          0.15,
          0
        ],
        "text-font": ["DIN Pro Italic", "Arial Unicode MS Regular"],
        "symbol-placement": "line-center",
        "text-pitch-alignment": "viewport",
        "text-field": ["coalesce", ["get", "name_en"], ["get", "name"]]
      },
      "paint": {
        "text-color": [
          "match",
          ["get", "class"],
          ["bay", "ocean", "sea"],
          "hsl(205, 88%, 78%)",
          "hsl(205, 60%, 90%)"
        ],
        "text-halo-color": "hsla(0, 0%, 100%, 0.5)"
      }
    },
    {
      "id": "water-point-label",
      "type": "symbol",
      "metadata": {
        "mapbox:featureComponent": "natural-features",
        "mapbox:group": "Natural features, natural-labels"
      },
      "source": "composite",
      "source-layer": "natural_label",
      "minzoom": 1,
      "filter": [
        "all",
        [
          "match",
          ["get", "class"],
          [
            "bay",
            "ocean",
            "reservoir",
            "sea",
            "water",
            "disputed_bay",
            "disputed_ocean",
            "disputed_reservoir",
            "disputed_sea",
            "disputed_water"
          ],
          ["match", ["get", "worldview"], ["all", "US"], true, false],
          false
        ],
        ["==", ["geometry-type"], "Point"]
      ],
      "layout": {
        "text-line-height": 1.3,
        "text-size": [
          "interpolate",
          ["linear"],
          ["zoom"],
          0,
          ["*", ["-", 16, ["sqrt", ["get", "sizerank"]]], 1],
          22,
          ["*", ["-", 22, ["sqrt", ["get", "sizerank"]]], 1]
        ],
        "text-font": ["DIN Pro Italic", "Arial Unicode MS Regular"],
        "text-field": ["coalesce", ["get", "name_en"], ["get", "name"]],
        "text-letter-spacing": [
          "match",
          ["get", "class"],
          "ocean",
          0.25,
          ["bay", "sea"],
          0.15,
          0.01
        ],
        "text-max-width": [
          "match",
          ["get", "class"],
          "ocean",
          4,
          "sea",
          5,
          ["bay", "water"],
          7,
          10
        ]
      },
      "paint": {
        "text-color": [
          "match",
          ["get", "class"],
          ["bay", "ocean", "sea"],
          "hsl(205, 88%, 78%)",
          "hsl(205, 60%, 90%)"
        ],
        "text-halo-color": "hsla(0, 0%, 100%, 0.5)"
      }
    },
    {
      "id": "settlement-minor-label",
      "type": "symbol",
      "metadata": {
        "mapbox:featureComponent": "place-labels",
        "mapbox:group": "Place labels, place-labels"
      },
      "source": "composite",
      "source-layer": "place_label",
      "minzoom": 2,
      "maxzoom": 13,
      "filter": [
        "all",
        ["<=", ["get", "filterrank"], 1],
        [
          "match",
          ["get", "class"],
          ["settlement", "disputed_settlement"],
          ["match", ["get", "worldview"], ["all", "US"], true, false],
          false
        ],
        [
          "step",
          ["zoom"],
          [">", ["get", "symbolrank"], 6],
          4,
          [">=", ["get", "symbolrank"], 7],
          6,
          [">=", ["get", "symbolrank"], 8],
          7,
          [">=", ["get", "symbolrank"], 10],
          10,
          [">=", ["get", "symbolrank"], 11],
          11,
          [">=", ["get", "symbolrank"], 13],
          12,
          [">=", ["get", "symbolrank"], 15]
        ]
      ],
      "layout": {
        "text-line-height": 1.1,
        "text-size": [
          "interpolate",
          ["cubic-bezier", 0.2, 0, 0.9, 1],
          ["zoom"],
          3,
          ["step", ["get", "symbolrank"], 11, 9, 10],
          6,
          ["step", ["get", "symbolrank"], 14, 9, 12, 12, 10],
          8,
          ["step", ["get", "symbolrank"], 16, 9, 14, 12, 12, 15, 10],
          13,
          ["step", ["get", "symbolrank"], 22, 9, 20, 12, 16, 15, 14]
        ],
        "text-radial-offset": [
          "step",
          ["zoom"],
          ["match", ["get", "capital"], 2, 0.6, 0.55],
          8,
          0
        ],
        "symbol-sort-key": ["get", "symbolrank"],
        "icon-image": [
          "step",
          ["zoom"],
          [
            "case",
            ["==", ["get", "capital"], 2],
            "border-dot-13",
            [
              "step",
              ["get", "symbolrank"],
              "dot-11",
              9,
              "dot-10",
              11,
              "dot-9"
            ]
          ],
          8,
          ""
        ],
        "text-font": ["DIN Pro Regular", "Arial Unicode MS Regular"],
        "text-justify": "auto",
        "text-anchor": [
          "step",
          ["zoom"],
          ["get", "text_anchor"],
          8,
          "center"
        ],
        "text-field": ["coalesce", ["get", "name_en"], ["get", "name"]],
        "text-max-width": 7
      },
      "paint": {
        "text-color": [
          "step",
          ["get", "symbolrank"],
          "hsl(0, 0%, 100%)",
          11,
          "hsl(0, 0%, 100%)",
          16,
          "hsl(0, 0%, 100%)"
        ],
        "text-halo-color": "hsl(0, 0%, 0%)",
        "text-halo-width": 1,
        "text-halo-blur": 1
      }
    },
    {
      "id": "settlement-major-label",
      "type": "symbol",
      "metadata": {
        "mapbox:featureComponent": "place-labels",
        "mapbox:group": "Place labels, place-labels"
      },
      "source": "composite",
      "source-layer": "place_label",
      "minzoom": 2,
      "maxzoom": 15,
      "filter": [
        "all",
        ["<=", ["get", "filterrank"], 1],
        [
          "match",
          ["get", "class"],
          ["settlement", "disputed_settlement"],
          ["match", ["get", "worldview"], ["all", "US"], true, false],
          false
        ],
        [
          "step",
          ["zoom"],
          false,
          2,
          ["<=", ["get", "symbolrank"], 6],
          4,
          ["<", ["get", "symbolrank"], 7],
          6,
          ["<", ["get", "symbolrank"], 8],
          7,
          ["<", ["get", "symbolrank"], 10],
          10,
          ["<", ["get", "symbolrank"], 11],
          11,
          ["<", ["get", "symbolrank"], 13],
          12,
          ["<", ["get", "symbolrank"], 15],
          13,
          [">=", ["get", "symbolrank"], 11],
          14,
          [">=", ["get", "symbolrank"], 15]
        ]
      ],
      "layout": {
        "text-line-height": 1.1,
        "text-size": [
          "interpolate",
          ["cubic-bezier", 0.2, 0, 0.9, 1],
          ["zoom"],
          3,
          ["step", ["get", "symbolrank"], 13, 6, 11],
          6,
          ["step", ["get", "symbolrank"], 18, 6, 16, 7, 14],
          8,
          ["step", ["get", "symbolrank"], 20, 9, 16, 10, 14],
          15,
          ["step", ["get", "symbolrank"], 24, 9, 20, 12, 16, 15, 14]
        ],
        "text-radial-offset": [
          "step",
          ["zoom"],
          ["match", ["get", "capital"], 2, 0.6, 0.55],
          8,
          0
        ],
        "symbol-sort-key": ["get", "symbolrank"],
        "icon-image": [
          "step",
          ["zoom"],
          [
            "case",
            ["==", ["get", "capital"], 2],
            "border-dot-13",
            [
              "step",
              ["get", "symbolrank"],
              "dot-11",
              9,
              "dot-10",
              11,
              "dot-9"
            ]
          ],
          8,
          ""
        ],
        "text-font": ["DIN Pro Medium", "Arial Unicode MS Regular"],
        "text-justify": [
          "step",
          ["zoom"],
          [
            "match",
            ["get", "text_anchor"],
            ["left", "bottom-left", "top-left"],
            "left",
            ["right", "bottom-right", "top-right"],
            "right",
            "center"
          ],
          8,
          "center"
        ],
        "text-anchor": [
          "step",
          ["zoom"],
          ["get", "text_anchor"],
          8,
          "center"
        ],
        "text-field": ["coalesce", ["get", "name_en"], ["get", "name"]],
        "text-max-width": 7
      },
      "paint": {
        "text-color": [
          "step",
          ["get", "symbolrank"],
          "hsl(0, 0%, 100%)",
          11,
          "hsl(0, 0%, 63%)",
          16,
          "hsl(0, 0%, 72%)"
        ],
        "text-halo-color": "hsl(0, 0%, 0%)",
        "text-halo-width": 1,
        "text-halo-blur": 1
      }
    },
    {
      "id": "country-label",
      "type": "symbol",
      "metadata": {
        "mapbox:featureComponent": "place-labels",
        "mapbox:group": "Place labels, place-labels"
      },
      "source": "composite",
      "source-layer": "place_label",
      "minzoom": 1,
      "maxzoom": 10,
      "filter": [
        "match",
        ["get", "class"],
        ["country", "disputed_country"],
        ["match", ["get", "worldview"], ["all", "US"], true, false],
        false
      ],
      "layout": {
        "icon-image": "",
        "text-field": ["coalesce", ["get", "name_en"], ["get", "name"]],
        "text-line-height": 1.1,
        "text-max-width": 6,
        "text-font": ["DIN Pro Medium", "Arial Unicode MS Regular"],
        "text-radial-offset": ["step", ["zoom"], 0.6, 8, 0],
        "text-justify": [
          "step",
          ["zoom"],
          [
            "match",
            ["get", "text_anchor"],
            ["left", "bottom-left", "top-left"],
            "left",
            ["right", "bottom-right", "top-right"],
            "right",
            "center"
          ],
          7,
          "auto"
        ],
        "text-size": [
          "interpolate",
          ["cubic-bezier", 0.2, 0, 0.7, 1],
          ["zoom"],
          1,
          ["step", ["get", "symbolrank"], 11, 4, 9, 5, 8],
          9,
          ["step", ["get", "symbolrank"], 22, 4, 19, 5, 17]
        ]
      },
      "paint": {
        "icon-opacity": [
          "step",
          ["zoom"],
          ["case", ["has", "text_anchor"], 1, 0],
          7,
          0
        ],
        "text-color": "hsl(0, 0%, 100%)",
        "text-halo-color": "hsl(0, 0%, 0%)",
        "text-halo-width": 1.25
      }
    },
  ],
}

export default mapStyle