import React, { useRef, useState } from 'react';
import SpeciesList from './SpeciesList';

export default function SpeciesButton(props: any) {
  const ref = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);

  function handleClick(event: React.MouseEvent<HTMLDivElement>) {
    setExpanded(!expanded);
  }
  function close(event: MouseEvent) {
    if (ref.current!.contains(event.target as Node)) return;
    setExpanded(false)
  }

  return (
    <div ref={ref} className='species-button-container'>
      {expanded 
        ? <SpeciesList onClickOutside={close} onSelected={(selected: any) => {
          props.onSelected(selected);
          setExpanded(false);
        }} group={props.group} /> 
        : null
      }
      <div onClick={handleClick}>
        <div className='species-button' >
          <img src={props.img} alt={props.label} height="100%"/>
          <div className="species-button-after" style={{ borderColor: props.group.color }} />
        </div>
        <p>{props.label}</p>
      </div>
    </div>
  )
}