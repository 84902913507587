import React, { CSSProperties } from 'react';
import Clock from './Clock';

interface ITrackerStatusProps {
  tracking: number;
  style: CSSProperties
}

export default function TrackerStatus(props: ITrackerStatusProps) {
  return (
    <div className="tracker-status" style={props.style}>
      {props.tracking} Active Tracks<br />
      <Clock></Clock>
    </div>
  );
}