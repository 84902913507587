import React, { useEffect, useState } from 'react';
import { Popup } from 'react-map-gl';
import { getDaysSince } from '../utilities/time';
import { getGenderText, getTravelDistance, getProtectedStatusText } from '../utilities/misc';
import { Button } from '@mui/material';
import Loader from './Loader';

interface IAnimalPopupProps {
  selected: any;
  species: any;
  expanded: any;
  onClose: any;
  onDrawClick: any;
}

interface IAnimalPopupInformationProps {
  selected: any;
  species: any;
  history: any;
  onDrawClick: any;
}

const MORE=`Named for its tiger-like stripes. Tiger Sharks are abundant in the warm, tropical waters of the Coral Sea. Despite being fearsome hunters they are quite lazy when it comes to food, preferring to scavenge already dead or dying animals for an easy meal.<br />
Satellite tagging has given us a wealth of knowledge about these apex predators, from how they feed, to how far they travel and how deep they go - data which helps to drive best-practice management and conservation strategies.`
const configValue: string = (process.env.REACT_APP_biotracker_service as string);

function AnimalPopupInformation(props: IAnimalPopupInformationProps) {
  const [distance, setDistance] = useState(0);
  const [duration, setDuration] = useState(0);

  const [lastPing, setLastPing] = useState(0);

  const [more, setMore] = useState(false);
  
  useEffect(() => {
    if (props.history === null) return;

    setDistance(getTravelDistance(props.history!.positions));
    setDuration(getDaysSince(props.selected.tagged));
    setLastPing(getDaysSince(props.selected));
  }, [props.selected, props.history, setDistance])

  return (
    <div className="popup-content">
      {props.history 
      ? (
        <div>
          {props.selected.sponsor && <div className='info-box-footer' style={{ marginTop: 0, width: 'unset' }}>Sponsored by: {props.selected.sponsor}</div>}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <span style={{ fontSize: "0.6rem" }}>TRACKED</span><br />
              <span style={{ fontSize: "1.5rem", fontWeight: 'bold' }}>{duration}</span><span style={{ fontSize: "0.8rem" }}>d</span>
            </div>
            <div>
            <span style={{ fontSize: "0.6rem" }}>DISTANCE TRAVELLED</span><br />
            <span style={{ fontSize: "1.5rem", fontWeight: 'bold' }}>{distance.toFixed(0)}</span><span style={{ fontSize: "0.8rem" }}>kms</span>
            </div>
            <div>
            <span style={{ fontSize: "0.6rem" }}>AVG. SPEED</span><br />
            <span style={{ fontSize: "1.5rem", fontWeight: 'bold' }}>{ (distance / (duration - lastPing) /24).toFixed(1) }</span><span style={{ fontSize: "0.8rem" }}>km/h</span>
            </div>
          </div>
          <p style={{ lineHeight: 1 }}>
            <span style={{ fontSize: "0.8rem" }}>{props.selected.species}</span><br />
            <span style={{ fontSize: "0.7rem", opacity: 0.5 }}>{props.species
              ? props.species.scientificName
              : ''}</span>
          </p>
          {props.selected.info
            ? (
              <p>
                <span style={{ fontSize: "0.7rem", whiteSpace: "pre-wrap" }}>{props.selected.info}</span><br />
              </p>
              )
            : (<></>)
          }
          {props.species && props.species.description && more && <><span style={{ fontSize: "0.7rem" }}>{props.species.description}</span><br /></>}
          {props.species && props.species.description && (<span 
            onClick={() => setMore(!more)} 
            style={{ fontSize: "0.7rem", color: 'deepskyblue', cursor: 'pointer' }}>
              Show {more ? 'less' : 'more'} on species
          </span>)}
          <hr style={{ borderBottom: 0, opacity: 0.5 }}/>
          <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '0.8rem', textDecoration: 'underline', marginBottom: '5px' }}>
            <span>{props.species && !props.species.protected && 'Not '}Protected</span>
            <span style={{ marginLeft: '10px' }}>IUCN: {getProtectedStatusText(props.species && props.species.status)}</span>
          </div>
          <Button 
            variant="outlined" 
            onClick={() => props.onDrawClick(props.history)}
            style={{ width: '100%' }}>
              See Tracking History
            </Button>
        </div>
      ) : <Loader style={{ marginTop: "10%", marginLeft: '-10px' }} />}
    </div>
  )
}

export default function AnimalPopup(props: IAnimalPopupProps) {

  const [additional, setAdditional] = useState(null);
  const [lastPing, setLastPing] = useState(0);
  const [gender, setGender] = useState('Unknown');

  useEffect(() => {
    (async () => {
      setGender(getGenderText(props.selected));
      setLastPing(getDaysSince(props.selected));

      if (props.expanded) {
        const resp = await fetch(`${configValue}/api/v1/animals/${props.selected.tagId}`);
        setAdditional(await resp.json());
      }

    })();
    return () => setAdditional(null);
  }, [props.selected, props.expanded, setAdditional, setGender, setLastPing])
  
  return (
    <Popup
      anchor="top"
      longitude={Number(props.selected.position.longitude)}
      latitude={Number(props.selected.position.latitude)}
      onClose={props.onClose}
      offset={[150, props.expanded ? -330 : -130]}
    >
      <div className="popup-header" style={{ backgroundImage: `url("${props.species ? props.species.image : ''}")`}}>
        <span style={{ fontSize: '1.5em', fontWeight: 'bold' }}>
          {props.selected.name}
        </span><br />
        <span style={{ fontSize: '1.2em' }}>
          {gender}, {props.selected.size}cm 
        </span><br />
        <span style={{ fontSize: '0.9em' }}>
          Last Ping: {lastPing > 365 
            ? (`${Math.floor(lastPing / 365)} year${lastPing > (365 * 2) ? 's' : ''} ago`)
            : `${lastPing} days ago`} 
        </span>
      </div>
      {props.expanded 
        ? <AnimalPopupInformation
            selected={props.selected}
            species={props.species}
            history={additional}
            onDrawClick={props.onDrawClick} /> 
        : null}
    </Popup>
  )
}