import React from 'react';

interface ILoaderProps {
  fullScreen?: boolean;
  style?: any
}
export default function Loader(props: ILoaderProps) {
  return (
    <div className="loader-container" style={props.fullScreen ? {
      height: '100vh',
      width: '100vw'
    }: {}}>
      <div className="loader" style={props.style}></div>
    </div>
  )
}