import turf from 'turf';

interface IPosition {
  latitude: number;
  longitude: number;
}

export function getGenderText(entry) {
  if (entry.sex.toLowerCase() === 'f') {
    return 'Female';
  }
  else if (entry.sex.toLowerCase() === 'm') {
    return 'Male'
  }
  return 'Unknown'
}

export function getTravelDistance(history: Array<IPosition> | null) {
  if (history === null) return 0;

  return history.reduce((p, c, idx) => {
    if (history.length === idx + 1) return p;
    return p + turf.distance(
      turf.point([c.longitude, c.latitude]),
      turf.point([history[idx+1].longitude, history[idx+1].latitude]),
      'kilometers'
    )
  }, 0)
}

export function getProtectedStatusText(status) {
  if (status === 'EX') return 'Extinct';
  if (status === 'EW') return 'Extinct in the Wild'
  if (status === 'CR') return 'Critically Endangered';
  if (status === 'EN') return 'Endangered';
  if (status === 'VU') return 'Vulnerable';
  if (status === 'NT') return 'Near Threatened';
  if (status === 'LC') return 'Least Concern';
  if (status === 'DD') return 'Data Deficient';
  if (status === 'NE') return 'Not Evaluated'
  return 'Unknown'
}