import React, { useMemo } from 'react';
import { getLatestPing } from '../utilities/time';

export default function SpeciesListEntry(props: any) {
  function handleClick() {
    props.onSelected(props.item);
  }

  return (
    <div>
      <li className='species-list-item' onClick={handleClick}>
        <table width={"100%"}>
          <tbody>
            <tr>
              <td>
                <span style={{ display: "block" }}>{props.item.name}</span>
                <span style={{ display: "block", fontSize: "0.8rem" }}>{props.item.individuals.length} {props.groupName}</span>
                <span style={{ display: "block", fontSize: "0.8rem" }}>Latest Ping: {getLatestPing(props.item.individuals)} days ago</span>
              </td>
              <td style={{textAlign: 'right'}}>&gt;</td>
            </tr>
          </tbody>
        </table>
      </li>
    </div>
  )
}