import React, { useEffect, useRef, useMemo } from 'react';
import AnimalListEntry from './AnimalListEntry';
import { getDaysSince } from '../utilities/time';


export default function SpeciesList(props: any) {
  const { onSelected, onClickOutside, group } = props;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      onClickOutside && onClickOutside(event);
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  return (
    <div className="species-popup" style={{ position: 'absolute', left: '260px' }}>
      <p className='species-popup-header'>{group.individuals.length} Tracked {props.name}</p>
      {group.individuals.length !== 0
        ? (
          <ul className='species-list'>
            {group.individuals
              .map((entry: any) => {
                return (<AnimalListEntry key={entry._id} item={entry} lastPing={getDaysSince(entry)} onSelected={onSelected} />)
              }).sort((a, b) => {
                return a.props.lastPing - b.props.lastPing
              })}
          </ul>
        )
        : <div style={{ margin: 'auto auto', width: '100%', textAlign: 'center' }}>COMING SOON</div>
      }
    </div>
  )
}