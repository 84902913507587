import React, { useMemo } from 'react';
import SpeciesButton from './SpeciesButton';

interface ISpeciesButtonBarProps {
  groups: Array<any>
  onSelected: any;
}
export default function SpeciesButtonBar(props: ISpeciesButtonBarProps) {
  const speciesButtons = useMemo(
    () => {    
      return props.groups.filter(group => group.name.toLowerCase() !== 'crocodile' || group.entries.length !== 0).map((current, index) => (
        <SpeciesButton
          key={`species-button-${index}`}
          img={current.image}
          group={current}
          onSelected={props.onSelected} />
      ))
    },
    [props.groups, props.onSelected]
  )

  return (
    <div className="button-container">
      {speciesButtons}
    </div>
  )
}