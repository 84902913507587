import React, { useEffect, useRef, useMemo, useState } from 'react';
import SpeciesListEntry from './SpeciesListEntry';
import AnimalList from './AnimalList';
import { getDaysSince } from '../utilities/time';

export default function SpeciesList(props: any) {
  const ref = useRef<HTMLUListElement>(null);
  const [selected, setSelected] = useState<any | null>(null);
  const { onClickOutside } = props;

  const listItems = useMemo(() => props.group.entries
    .filter((entry: any) => entry.individuals.length !== 0)
    .map((entry: any) => {
      return (<SpeciesListEntry 
        key={entry.name} 
        groupName={props.group.name} 
        item={entry} 
        lastPing={getDaysSince(entry)} 
        onSelected={(item) => setSelected(item)} />)
    }).sort((a, b) => {
      return a.props.lastPing - b.props.lastPing
    }),
    [props.group.entries, props.group.name]
  )
  
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      onClickOutside && onClickOutside(event);
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  return (
    <div className="popup-container" style={{ position: 'relative' }}>
      <div className="species-popup">
        <p className='species-popup-header'>{props.group.entries.length} Species</p>
        {props.group.entries.length !== 0
          ? (
            <ul className='species-list' ref={ref}>
              {listItems}
            </ul>
          )
          : <div style={{ margin: 'auto auto', width: '100%', textAlign: 'center' }}>COMING SOON</div>
        }
      </div>
      {selected && (
        <AnimalList group={selected} onSelected={props.onSelected} onClickOutside={onClickOutside} />
      )}
    </div>
  )
}