import './App.css';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Map, { MapRef, ViewStateChangeEvent } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import turf from 'turf';

import {
  Header,
  Loader,
  Path,
  TrackerStatus
} from './components'

import AnimalMarker, { MarkerState } from './components/AnimalMarker';
import AnimalPopup from './components/AnimalPopup';
import SpeciesButtonBar from './components/SpeciesButtonBar';

import mapStyle from './utilities/mapStyle';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax 
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const TOKEN = 'pk.eyJ1IjoibWR1bmJhYmluIiwiYSI6ImNsaWpkcGg1ODA2OG0zZ3BvNmw4bG5oanAifQ.XU69rVsmtdtJAwQH-IEEgg';

const COLOR_MAP = {
  'shark': '#00aeff',
  'turtle': '#2de181',
  'whale shark': '#e7257d',
  'manta ray': '#ff9611',
  'crocodile': '#FF4236'
}

const configValue: string = (process.env.REACT_APP_biotracker_service as string);


function App() {
  const ref = useRef<MapRef>(null);

  const [loaded, setLoaded] = useState(false);
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [opacity, setOpacity] = useState(1);

  const [isEasing, setIsEasing] = useState(false);
  const [selected, setSelected] = useState<any | null>(null);
  const [position, setPosition] = useState({
    lng: 145.974,
    lat: -16.791
  });

  const [path, setPath] = useState<any | null>(null);

  const [groups, setGroups] = useState<Array<any>>([]);
  const [species, setSpecies] = useState({})

  const onMouseEnter = useCallback((individual: any) => {
    if (selected !== null) return;
    setSelected(null);
    setPopupInfo(individual);
  }, [selected])

  const onMouseLeave = useCallback(() => {
    if (selected !== null) return;
    setPopupInfo(null);
    setSelected(null);
  }, [selected]);

  const selectIndividual = useCallback((individual: any) => {
    setPopupInfo(individual);
    setSelected(individual);

    setPath(null);

    if (!individual) return;

    const intervalId = setInterval(() => {
      if (ref.current!.isEasing()) return;
      setIsEasing(false);
      clearInterval(intervalId);
    }, 10);
  }, [])

  const showIndividualPath = useCallback((history: any) => {

    const bounds = turf.bbox(turf.featureCollection(history.positions.map(p => turf.point([
      p.longitude >= 0 ? p.longitude : 360 + p.longitude,
      p.latitude
    ]))));

    ref.current!.fitBounds(bounds as mapboxgl.LngLatBoundsLike, { padding: 20 });

    const intervalId = setInterval(() => {
      if (ref.current!.isEasing()) return;
      setPath(history.positions);
      clearInterval(intervalId);
    }, 10);
  }, [])

  async function initSpecies() {
    const resp = await fetch(`${configValue}/api/v1/species`);
    const data = await resp.json();

    const result = data.reduce((previous, s) => {
      return { ...previous, ...{ [s.name.toLowerCase()]: Object.assign(s, {individuals: []}) } }
    }, {});

    setSpecies(result);
    return result;
  }
  async function initGroups(speciesData) {
    const resp = await fetch(`${configValue}/api/v1/animals`);
    const animals = await resp.json();
    console.log(speciesData)
    
    animals.forEach(animal => {
      const key = animal.species.toLowerCase();
      if (key in speciesData) {
        speciesData[key].individuals.push(animal);
      }
    });

    setGroups([
      {
        name: "Sharks",
        color: COLOR_MAP['shark'],
        image: "/images/shark.icon.png",
        entries: Object.values(speciesData).filter((entry: any) => entry.group === 'shark')
      },
      {
        name: "Turtles",
        color: COLOR_MAP['turtle'],
        image: "/images/turtle.icon.png",
        entries: Object.values(speciesData).filter((entry: any) => entry.group === 'turtle')
      },
      {
        name: "Whale Sharks",
        color: COLOR_MAP['whale shark'],
        image: "/images/whale.shark.icon.png",
        entries: Object.values(speciesData).filter((entry: any) => entry.group === 'whale shark')
      },
      {
        name: "Manta Ray",
        color: COLOR_MAP['manta ray'],
        image: "/images/ray.icon.png",
        entries: Object.values(speciesData).filter((entry: any) => entry.group === 'manta ray')
      },
      {
        name: "Crocodile",
        color: COLOR_MAP['crocodile'],
        image: "/images/crocodile.icon.png",
        entries: Object.values(speciesData).filter((entry: any) => entry.group === 'crocodile')
      }
    ]);
  }

  useEffect(() => {
    (async () => {
      const speciesData = await initSpecies();
      await initGroups(speciesData);

    })();
  }, [])
  
  const markers = useMemo(
    () =>
      groups.reduce((previous: Array<React.JSX.Element>, group) => {
        if (!loaded) return previous;
        
        return previous.concat(group.entries.reduce((previousAnimals, species) => {
          if (species.individuals.length === 0) return previousAnimals;

          return (previousAnimals.concat(species.individuals!.map((entry: any, index: number) => (
            <AnimalMarker
              key={`${species.name}-${index}`}
              group={group}
              entry={entry}
              state={popupInfo && popupInfo === entry
                ? MarkerState.HIGHLIGHTED
                : (selected && selected !== entry ? MarkerState.HIDDEN : MarkerState.NORMAL)}
              onMouseEnter={() => onMouseEnter(entry)}
              onMouseLeave={() => onMouseLeave()}
              onClick={e => {
                e.originalEvent.stopPropagation();
                selectIndividual(selected !== entry ? entry : null);
                ref.current!.easeTo({
                  center: {
                    lat: entry.position.latitude,
                    lng: entry.position.longitude
                  },
                  zoom: 8,
                  duration: 1000
                });
              }} />
          ))))
        }, []))
      }, []),
    [loaded, selected, popupInfo, groups, onMouseEnter, onMouseLeave, selectIndividual]
  )

  function onMove(event: ViewStateChangeEvent) {
    setPosition({
      lat: ref.current!.getCenter().lat,
      lng: ref.current!.getCenter().lng,
    });
  }

  function onZoom(event: ViewStateChangeEvent) {
    if (event.viewState.zoom > 5) {
      ref.current?.getMap().setPaintProperty('satellite', 'raster-opacity', 0)
    } else {
      ref.current?.getMap().setPaintProperty('satellite', 'raster-opacity', 1)
    }
    if (event.viewState.zoom < 3) return setOpacity(1);
    setOpacity(Math.max(0, 1 - (event.viewState.zoom - 3)))
  }

  function onPathExit() {
    setPath(null);
  }

  if (groups.length === 0) return (<Loader fullScreen={true} />)

  return (
    <div className="App">
      <Header position={position} opacity={opacity} />
      <TrackerStatus tracking={groups.reduce((p, c) => p + c.entries.length, 0)} style={{ opacity }} />
      <Map
        ref={ref}
        initialViewState={{
          latitude: position.lat,
          longitude: position.lng,
          zoom: 3,
          bearing: 0,
          pitch: 0,
        }}
        projection={'globe'}
        mapLib={mapboxgl}
        mapStyle={mapStyle}
        maxZoom={12}
        mapboxAccessToken={TOKEN}
        fog={{
          "range": [0.8, 8],
          "color": "#111111",
          "horizon-blend": 0.005,
          "space-color": "#000000",
          "star-intensity": 0.1
        }}
        style={{ position: "absolute" }}
        onMove={onMove}
        onZoom={onZoom}
        onLoad={() => setLoaded(true)}>
        {markers}

        {!path && !isEasing && popupInfo && (
          <AnimalPopup
            selected={popupInfo}
            species={species[popupInfo.species.toLowerCase()]}
            expanded={selected !== null}
            onClose={() => {
              setPopupInfo(null);
              setSelected(null);
            }}
            onDrawClick={showIndividualPath}
          />

        )}
        {path && selected && (
          <>
            <Path
              selected={selected}
              species={species[popupInfo.species.toLowerCase()]}
              path={path}
              color={COLOR_MAP[selected.group]}
              onExitPath={onPathExit} />
          </>
        )}
      </Map>
      {
        !path && (
          <SpeciesButtonBar
            groups={groups}
            onSelected={(individual) => {
              setIsEasing(true);
              ref.current!.easeTo({
                center: {
                  lat: individual.position.latitude,
                  lng: individual.position.longitude
                },
                zoom: 8,
                duration: 3000
              });
              selectIndividual(individual);
            }}
          />
        )
      }
    </div >
  );
}

export default App;
