import React, { useEffect, useState } from 'react';
import { DateTime } from "luxon";

export default function Clock() {
  const [time, setTime] = useState(DateTime.now().setZone('Australia/Brisbane'))
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(DateTime.now().setZone('Australia/Brisbane'))
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>{time.toFormat('hh:mm:ss a')} GBRT</>
  )
}