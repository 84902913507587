import React, { useMemo } from 'react';

export default function SpeciesListEntry(props: any) {
  function handleClick() {
    props.onSelected(props.item);
  }

  return (
    <li className='species-list-item' onClick={handleClick}>
      <span style={{ display: "block"}}>{props.item.name}</span>
      <span style={{ display: "block", fontSize: "0.8rem" }}>Latest Ping: {props.lastPing}d ago</span>
    </li>
  )
}