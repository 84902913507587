
export function getDaysSince(entry) {
  const diff = Math.abs(new Date().getTime() - new Date(entry.date).getTime());
  return Math.ceil(diff / (1000 * 3600 * 24)); 
}

export function getDaysBetween(earliest, latest) {
  const diff = Math.abs(new Date(earliest.date).getTime() - new Date(latest.date).getTime());
  return Math.ceil(diff / (1000 * 3600 * 24)); 
}

export function getLatestPing(entries) {
  const now = new Date().getTime();

  const latest = entries.reduce((previous, entry) => {
    const diff = Math.abs(now - new Date(entry.date).getTime());
    return diff < previous ? diff : previous;
  }, Infinity)
  return Math.ceil(latest / (1000 * 3600 * 24)); 
}